import $ from 'jquery'
import {resetDropzone} from './form'


// Fonction contenant tous les messages
function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.',
      equalToPassword: 'Please provide the same password.',
      wordCount: 'The text should not exceed 500 words.',
      cardHolder: 'Please provide a valid card name.',
      cardNumber: 'Please provide a valid card number.',
      cardExpiryDate: 'Please provide a card with a valid expiration date.',
      cardSecurityCode: 'Please provide a valid card security number.',
      conditions: 'You must read and accept the commitment form.',
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
      equalToPassword: 'Veuillez fournir le même mot de passe s\'il vous plait.',
      wordCount: 'Le texte ne doit pas dépasser les 500 mots.',
      cardHolder: 'Veuillez fournir un nom de carte valide.',
      cardNumber: 'Veuillez fournir un numéro de carte valide.',
      cardExpiryDate: 'Veuillez fournir une carte avec une date d\'expiration valide.',
      cardSecurityCode: 'Veuillez fournir un numéro de sécurité de carte valide.',
      conditions: 'Vous devez lire et accepter le formulaire d\'engagement.',
    }
  }
}


// Fonction exécutant la validation de chaque formulaire
// buttonNoSubmit : true / false - le bouton ne permet pas de soumettre le formulaire, permet la prise en charge par un autre script
export function formValidation(form, submit, rules, messages, formWithSubmitDisabled, handler, buttonNoSubmit) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        return // eslint-disable-next-line semi

      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if (nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {
      let type = element[0].getAttribute('type')
      if (type == 'radio' || type == 'checkbox')
        error.appendTo(element.parent().parent().parent().find('.js-form-error'))
      else
        error.appendTo(element.parent().parent().find('.js-form-error'))

      // Ajouter la class d'erreur au champs
      var errorField = element.closest('.form__grid-item').children()
      var className = errorField.attr('class').split(' ')[0]
      errorField.addClass(className + '--error')
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    },
    // Référence: https://stackoverflow.com/questions/21953694/enabling-disabled-button-after-successful-jquery-validation
    onkeyup: function (element, event) {
      if (formWithSubmitDisabled) {
        if (event.which === 9 && this.elementValue(element) === '') {
          return
        } else if (element.name in this.submitted) {
          this.element(element)
        }

        this.checkForm()

        if (this.valid()) {
          $(submit).prop('disabled', false)
        } else {
          $(submit).prop('disabled', 'disabled')
        }
      }
    },
    onclick: function (element) {
      if (formWithSubmitDisabled) {
        // click on selects, radiobuttons and checkboxes
        if (element.name in this.submitted) {
          this.element(element)
        }
        // or option elements, check parent select in that case
        else if (element.parentNode.name in this.submitted) {
          this.element(element.parentNode)
        }

        this.checkForm()

        if (this.valid()) {
          $(submit).prop('disabled', false)
        } else {
          $(submit).prop('disabled', 'disabled')
        }
      }
    }
  })

  // Valeur par defaut du bouton de soumission
  if (typeof buttonNoSubmit == 'undefined') {
    buttonNoSubmit = false
  }

  if (!buttonNoSubmit) {
    $(submit).on('click', function () {
      console.log('form validation submit')
      $(form).submit()
    })
  }

}


// Reset de validation
export function formSuccess(form, data) {
  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading')
    $('.stripe-loading-indicator').addClass('loaded')
    $(form)[0].reset()
    resetDropzone(form)
    if (typeof grecaptcha !== 'undefined') grecaptcha.reset()

    //<div class="alert alert-success">x</div>

    var message = 'Le formulaire est bien envoyé !'
    var confirmationContainer = form.querySelector('.js-confirmation-message')

    if (confirmationContainer.innerHTML != '') {
      message = confirmationContainer.innerHTML
    } else {
      if (document.getElementsByTagName('html')[0].getAttribute('lang') == 'en') {
        message = 'The form has been sent !'
      }
    }

    // Créer l'élément
    var confirmationNode = document.createElement('div')
    confirmationNode.className = 'alert alert-success'
    confirmationNode.textContent = message

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode)

    // Cacher le formulaire
    form.style.display = 'none'

    //$.oc.flashMsg({text: message ,class: 'success'})
  }
}


// La validation des courriels
function validationEmail() {
  $.validator.addMethod('courriel', function (value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

// La validation d'une date d'expiration d'une carte
function validationCardExpiryDate() {
  $.validator.addMethod('cardExpiryDate', function (value) {
    if (value.length === 5) {
      let minMonth = new Date().getMonth() + 1
      let minYear = parseInt(new Date().getFullYear().toString().substr(2, 2), 10)
      let month = parseInt(value.substr(0, 2), 10)
      let year = parseInt(value.substr(3, 2), 10)

      return (!month || !year || year > minYear || (year === minYear && month >= minMonth))
    } else {
      return false
    }
  })
}


// La validation du formulaire d'exemple
export function formExample() {
  validationEmail()

  var m = getMessages()

  var rules = {
    dropdownExample: {required: true},
    firstname: {required: true},
    lastname: {required: true},
    email: {required: true, courriel: true},
    phone: {required: true, minlength: 12},
    message: {required: true},
    cv: {required: true},
    radioExampleInline: {required: true},
    checkbox: {required: true},
  }
  var messages = {
    dropdownExample: {required: m.required},
    firstname: {required: m.required},
    lastname: {required: m.required},
    email: {required: m.required, courriel: m.email, email: m.email},
    phone: {required: m.required, minlength: m.phone},
    message: {required: m.required},
    cv: {required: m.required},
    radioExampleInline: {required: m.required},
    checkbox: {required: m.required},
  }

  formValidation('#form-example', '#form-example-submit', rules, messages)
}


// La validation du formulaire du profil d'entreprise personnelles (profile-company)
export function formProfileCompany(formID, formSubmit) {
  validationEmail()

  var m = getMessages()

  var rules = {
    b_name: {required: true},
    // type: {required: true},
    // address: {required: true},
    // email: {required: true, courriel: true},
    // phone: {required: true, minlength: 12},
    // province: {required: true},
    // city: {required: true},
    // zip: {required: true, minlength: 6},
  }
  var messages = {
    name: {required: m.required},
    type: {required: m.required},
    address: {required: m.required},
    email: {required: m.required, courriel: m.email, email: m.email},
    phone: {required: m.required, minlength: m.phone},
    province: {required: m.required},
    city: {required: m.required},
    zip: {required: m.required, minlength: m.postale},
  }

  // formValidation(formID, formSubmit, rules, messages)
}


// La validation du formulaire d'informations personnelles (profile-personnal)
export function formProfilePersonnal(formID, formSubmit) {
  validationEmail()

  var m = getMessages()

  var rules = {
    firstname: {required: true},
    lastname: {required: true},
    email: {required: true, courriel: true},
    phone: {required: true, minlength: 12},
    officePhone: {minlength: 12},
    'password-confirmation': {equalTo: '#form-profile-personnal-password'},
  }
  var messages = {
    firstname: {required: m.required},
    lastname: {required: m.required},
    email: {required: m.required, courriel: m.email, email: m.email},
    phone: {required: m.required, minlength: m.phone},
    officePhone: {minlength: m.phone},
    password: {required: m.required},
    'password-confirmation': {required: m.required, equalTo: m.equalToPassword},
  }

  formValidation(formID, formSubmit, rules, messages)
}


// La validation du formulaire de modification d'un maillage (networking)
export function formNetworking(formID, formSubmit) {
  var m = getMessages()

  var rules = {
    area: {required: true},
    status: {required: true},
    note: {required: true, maxlength: 500},
    'files[]': { accept: false },
  }
  var messages = {
    area: {required: m.select},
    status: {required: m.select},
    note: {required: m.required, maxlength: m.wordCount},
  }

  formValidation(formID, formSubmit, rules, messages)
}


// La validation du formulaire de connexion (connexion)
export function formConnexion(formID, formSubmit) {
  validationEmail()

  var m = getMessages()

  var rules = {
    email: {required: true, courriel: true},
    password: {required: true},
  }
  var messages = {
    email: {required: m.required, courriel: m.email, email: m.email},
    password: {required: m.required},
  }

  formValidation(formID, formSubmit, rules, messages)
}


// La validation du formulaire de reinitialisation (reinitialisation)
export function formReinitialisation(formID, formSubmit) {
  validationEmail()

  var m = getMessages()

  var rules = {
    email: {required: true, courriel: true},
  }
  var messages = {
    email: {required: m.required, courriel: m.email, email: m.email},
  }

  formValidation(formID, formSubmit, rules, messages)
}


// La validation du formulaire de contact
export function formContact() {
  validationEmail()

  var m = getMessages()

  var rules = {
    firstname: {required: true},
    lastname: {required: true},
    email: {required: true, courriel: true},
    phone: {required: true, minlength: 12},
    message: {required: true},
  }
  var messages = {
    firstname: {required: m.required},
    lastname: {required: m.required},
    email: {required: m.required, courriel: m.email, email: m.email},
    phone: {required: m.required, minlength: m.phone},
    message: {required: m.required},
  }

  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}


// La validation du formulaire de code promo (purchase)
export function formPromoCode(formID, formSubmit) {
  var m = getMessages()

  var rules = {
    code: {required: true},
  }
  var messages = {
    code: {required: m.required},
  }

  formValidation(formID, formSubmit, rules, messages)
}


// La validation du formulaire d'informations personnelles (public-purchase)
export function formPublicPurchase(formID, formSubmit) {
  validationEmail()

  var m = getMessages()

  var rules = {
    firstname: {required: true},
    lastname: {required: true},
    b_name: {required: true},
    phone: {required: true, minlength: 12},
    email: {required: true, courriel: true},
    password: {required: true},
    'password-confirmation': {required: true, equalTo: '#form-public-purchase-password'},
  }
  var messages = {
    firstname: {required: m.required},
    lastname: {required: m.required},
    b_name: {required: m.required},
    phone: {required: m.required, minlength: m.phone},
    email: {required: m.required, courriel: m.email, email: m.email},
    password: {required: m.required},
    'password-confirmation': {required: m.required, equalTo: m.equalToPassword},
  }

  $(formSubmit).prop('disabled', 'disabled')
  formValidation(formID, formSubmit, rules, messages, true)
}

// La validation du formulaire de paiement (payment-purchase)
export function formPaymentPurchase(formID, formSubmit) {
  validationCardExpiryDate()

  var m = getMessages()

  var rules = {
    'card-holder': {required: true,},
    'card-number': {required: true, minlength: 18},
    'card-expiry-date': {required: true, cardExpiryDate: true},
    'card-security-code': {required: true, minlength: 3},
    'accept-conditions': {required: true},
  }
  var messages = {
    'card-holder': {required: m.required,},
    'card-number': {required: m.required, minlength: m.cardNumber},
    'card-expiry-date': {required: m.required, cardExpiryDate: m.cardExpiryDate},
    'card-security-code': {required: m.required, minlength: m.cardSecurityCode},
    'accept-conditions': { required: m.conditions },
  }

  formValidation(formID, formSubmit, rules, messages, true, undefined, true)
}
