import $ from 'jquery'


// Le package à appliquer pour les masques
export function masksPackage() {
  formatPhone()
  formatPostalCode()
  formatCardNumber()
  formatCardExpiryDate()
  formatCardSecurityCode()
}

// Le formatage du numéro de téléphone
function formatPhone() {
  let $phones = $('.js-mask-phone')
  $phones.each(function() {
    let $field = $(this)
    dynamicMask($field.val(), $field, phoneOptions)
  })
}
// Options for phone formating
let phoneOptions = {
  onKeyPress: function(cep, e, field, options) {
    console.log('tested')
    dynamicMask(cep,field,options)
  },
  translation: {N: {pattern: /[0-9\s]/}} // Space and number are permit on this char to switch the mask
}
// Select the mask with the value
function dynamicMask(value, field, options) {
  // Dynamic mask change
  let masks = ['000 000-0000','0 000 000-0000']
  let mask = (value.length < 3) ? '0N000 000-0000' : (value[1] == ' ') ? masks[1] : masks[0]
  $(field).mask(mask, options)
}

// Le formatage du code postal
function formatPostalCode() {
  $('.js-mask-postal-code').mask('Z0Z 0Z0', {
    translation: {
      Z: {
        pattern: /[A-Z, a-z]/,
      },
    },
  })
  // Forcer les majuscules
  $('.js-mask-postal-code').keyup(function(){
    $(this).val($(this).val().toUpperCase())
  })
}


// Le formatage du numéro de carte
function formatCardNumber() {
  $('.js-mask-card-number').mask('0000 0000 0000 0000')
}


// Le formatage de la date d'expiration d'une carte
function formatCardExpiryDate() {
  $('.js-mask-card-expiry-date').mask('00/00')
}


// Le formatage du code de sécurité d'une carte
function formatCardSecurityCode() {
  $('.js-mask-card-security-code').mask('0000')
}
