import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'

// Swiper de la bannière
export function swiperHomeBanner() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="home-banner-slide"]').length > 1) {

    // Création du slider
    window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: '[data-swiper="home-banner-prev"]',
        nextEl: '[data-swiper="home-banner-next"]'
      },
      pagination: {
        el: '[data-swiper="home-banner-pagination"]',
        type: 'bullets',
        clickable: true,
      },
      runCallbacksOnInit: true,
      on: {
        init: function(sw){
          let fraction = document.querySelector('[data-swiper="home-banner-pagination-fraction"]')
          let index
          let length

          if((sw.realIndex + 1) < 10) {
            index = (sw.realIndex + 1)
          } else {
            index = sw.realIndex + 1
          }

          if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
            length = (sw.slides.length - (sw.loopedSlides*2))
          } else {
            length = sw.slides.length - (sw.loopedSlides*2)
          }

          fraction.innerHTML = '<span>' + index + '</span>' + '<span>' + '/ ' + length + '</span>'
        }, //runs callback in initialization
        slideChange: function(sw){
          let fraction = document.querySelector('[data-swiper="home-banner-pagination-fraction"]')
          let index
          let length

          if((sw.realIndex + 1) < 10) {
            index = (sw.realIndex + 1)
          } else {
            index = sw.realIndex + 1
          }

          if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
            length = (sw.slides.length - (sw.loopedSlides*2))
          } else {
            length = sw.slides.length - (sw.loopedSlides*2)
          }

          fraction.innerHTML = '<span>' + index + '</span>' + '<span>' + '/ ' + length + '</span>'
        } //runs callback in slide change end
      },
    })
    // window['home-banner'].on('slideChange', function () {
    //   let photoCredits = document.querySelector('.visible-credits')
    //   if(photoCredits) {
    //     photoCredits.classList.remove('visible-credits')
    //   }
    // })
  }
}

// Swiper des événements en vedettes
export function swiperHomeEvents() {

  const nb_slides =  document.querySelectorAll('[data-swiper="home-events-slide"]').length
  // Si il y a plus qu'une slide
  if (nb_slides > 0) {

    // Création du slider
    window['home-events'] = new Swiper('[data-swiper="home-events"]', {
      loop: (nb_slides > 1) ? true : false,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      loopedSlides: 3,
      navigation: {
        prevEl: '[data-swiper="home-events-prev"]',
        nextEl: '[data-swiper="home-events-next"]'
      },
      runCallbacksOnInit: true,
      on: {
        init: function(sw){
          let fraction = document.querySelector('[data-swiper="home-events-pagination-fraction"]')
          if (fraction) {
            let index
            let length

            if((sw.realIndex + 1) < 10) {
              index = (sw.realIndex + 1)
            } else {
              index = sw.realIndex + 1
            }

            if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
              length = (sw.slides.length - (sw.loopedSlides*2))
            } else {
              length = sw.slides.length - (sw.loopedSlides*2)
            }

            fraction.innerHTML = '<span>' + index + '</span>' + '<span>' + '/ ' + length + '</span>'
          }
        }, //runs callback in initialization
        slideChange: function(sw){
          let fraction = document.querySelector('[data-swiper="home-events-pagination-fraction"]')
          let index
          let length

          if((sw.realIndex + 1) < 10) {
            index = (sw.realIndex + 1)
          } else {
            index = sw.realIndex + 1
          }

          if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
            length = (sw.slides.length - (sw.loopedSlides*2))
          } else {
            length = sw.slides.length - (sw.loopedSlides*2)
          }

          fraction.innerHTML = '<span>' + index + '</span>' + '<span>' + '/ ' + length + '</span>'
        } //runs callback in slide change end
      },
    })

    const setEventImageDimensionVariable = () => {
      const event = document.querySelector('[data-event-image]')
      if ( typeof event !== 'undefined' && event !== null) {
        document.documentElement.style.setProperty('--event-image-dimension', `${event.offsetHeight}px`)
      }
    }

    setEventImageDimensionVariable()

    OBSERVER.add({
      name: 'setEventImageDimensionVariable',
      event: 'resize',
      function: setEventImageDimensionVariable
    })

    OBSERVER.on('setEventImageDimensionVariable')
  }
}

// Swiper des partenaires
export function swiperBottomPartners() {
  let settings = {
    loop: true,
    speed: 500,
    autoplay: {
      delay: 7000,
    },
    slidesPerView: 2,
    spaceBetween: 30,
    breakpoints: {
      500: {
        slidesPerView: 3,
        loopedSlides: 0,
        spaceBetween: 20,
      },
      800: {
        slidesPerView: 4,
        loopedSlides: 0,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 5,
        loopedSlides: 0,
        spaceBetween: 20,
      }
    }
  }

  const swiperElement = document.querySelector('[data-swiper="bottom-partners"]')
  if(swiperElement) {
    // Création du slider
    window['bottom-partners'] = new Swiper('[data-swiper="bottom-partners"]', settings)

    // swiperInitialization() // Ça désactivait le slider en desktop, mais pourquoi? on veux pas ça

    OBSERVER.add({
      name: 'swiperInitialization',
      event: 'resize',
      function: swiperInitialization
    })

    OBSERVER.on('swiperInitialization')
  }

  function swiperInitialization() {
    let pageWidth = window.innerWidth

    if(pageWidth > 900) {
      if(swiperElement.classList.contains('swiper-container-initialized')) {
        window['bottom-partners'].disable()
      }
    } else if(pageWidth <= 900) {
      if(!swiperElement.classList.contains('swiper-container-initialized')) {
        window['bottom-partners'].enable()
      }
    }
  }
}

// Swiper d'une galerie d'images
export function swiperImages() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="images-swiper-slide"]').length > 1) {

    // Création du slider
    window['images-swiper'] = new Swiper('[data-swiper="images-swiper"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      loopedSlides: 3,
      navigation: {
        prevEl: '[data-swiper="images-swiper-prev"]',
        nextEl: '[data-swiper="images-swiper-next"]'
      },
      runCallbacksOnInit: true,
      on: {
        init: function(sw){
          let fraction = document.querySelector('[data-swiper="images-swiper-pagination-fraction"]')
          let index
          let length

          if((sw.realIndex + 1) < 10) {
            index = (sw.realIndex + 1)
          } else {
            index = sw.realIndex + 1
          }

          if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
            length = (sw.slides.length - (sw.loopedSlides*2))
          } else {
            length = sw.slides.length - (sw.loopedSlides*2)
          }

          fraction.innerHTML = '<span>' + index + '</span>' + '<span>' + '/ ' + length + '</span>'
        }, //runs callback in initialization
        slideChange: function(sw){
          let fraction = document.querySelector('[data-swiper="images-swiper-pagination-fraction"]')
          let index
          let length

          if((sw.realIndex + 1) < 10) {
            index = (sw.realIndex + 1)
          } else {
            index = sw.realIndex + 1
          }

          if((sw.slides.length - (sw.loopedSlides*2)) < 10) {
            length = (sw.slides.length - (sw.loopedSlides*2))
          } else {
            length = sw.slides.length - (sw.loopedSlides*2)
          }

          fraction.innerHTML = '<span>' + index + '</span>' + '<span>' + '/ ' + length + '</span>'
        } //runs callback in slide change end
      },
    })

    const setImageDimensionsVariable = () => {
      const image = document.querySelector('[data-image]')
      if ( typeof image !== 'undefined' && image !== null) {
        document.documentElement.style.setProperty('--image-dimensions', `${image.offsetHeight}px`)
      }
    }

    setImageDimensionsVariable()

    OBSERVER.add({
      name: 'setImageDimensionsVariable',
      event: 'resize',
      function: setImageDimensionsVariable
    })

    OBSERVER.on('setImageDimensionsVariable')
  }
}

// Swiper de la navigation horizontal
export function swiperNavigationHorizontal() {
  window['navigation-horizontal'] = new Swiper('[data-swiper="navigation-horizontal"]', {
    speed: 300,
    threshold: 10,
    slidesPerView: 'auto',
    spaceBetween: 50,
    freeMode: {
      enabled: true,
      momentumBounce: false,
    },
    mousewheel: {
      sensitivity: 0.5,
    },
    navigation: {
      nextEl: '[data-swiper="navigation-horizontal-next"]',
      prevEl: '[data-swiper="navigation-horizontal-prev"]',
      disabledClass: 'disabled'
    },
    runCallbacksOnInit: true,
    on: {
      init: function(sw) {
        let linkActive = document.querySelector('.navigation-horizontal__link.active')
        let slideActive = linkActive.closest('.navigation-horizontal__swiper-slide')
        sw.slideTo(slideActive.dataset.indexSlide, 1, false)
      }
    }
  })
}
