import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { fadeTransition } from './transitions/fade-transition.js'
import { backendKeyBind, calendar, ogContent, backToTopAndLinks, clickToScrollToBlock, infoBubble, listLoaded, changeSelect } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, clearInput, inputsAndTextareaLabel, tailSelect, changeRubricTitle, acceptConditions } from './functions/form.js'
import { overlayMenu, overlayShare, overlayPopup, overlayAccount, overlayBuy, overlayCalendar, overlayPagePopup, overlayFilters, overlayRecapBlock, overlayPurchaseConditions } from './functions/overlays.js'
import { Accordions } from './classes/accordions.js'
import Overlay from './vendor/overlay.js'
import { formExample, formSuccess, formProfileCompany, formProfilePersonnal, formNetworking, formConnexion, formReinitialisation, formContact, formPromoCode, formPublicPurchase, formPaymentPurchase } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { hundredVH, removeHoverOnMobile, sizeTitle } from './functions/helper.js'
import { swiperBottomPartners, swiperHomeBanner, swiperHomeEvents, swiperImages, swiperNavigationHorizontal } from './functions/sliders.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.listLoaded = listLoaded

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()
    hundredVH()
    overlayShare()
    overlayMenu()
    overlayAccount()
    overlayPagePopup()
    inputsAndTextareaLabel()
    clearInput()
    backendKeyBind()
    SCROLLFIRE.init()
    removeHoverOnMobile()
    sizeTitle({
      selectors: '.page-title__title, .page-title-with-image__title',
      caract01: 30,
      caract02: 50,
      caract03: 70
    })
    infoBubble({ toggle: '.photo-credits__hover-item', activeClass: 'visible-credits' })
    swiperBottomPartners()
    backToTopAndLinks()
    ShareButtons.update()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      fadeTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          dynamicPackage()
          formExample()
          calendar()
          initFormBuilder()
          recaptchaFormsCallback()
          window['lazyload'] = new LazyLoad()
        },
        afterEnter() {
          masksPackage()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner()
          swiperHomeEvents()
          sizeTitle({
            selectors: '.home-banner__title',
            caract01: 30,
            caract02: 50,
            caract03: 70
          })
          overlayPopup()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-events"]', scrollTo: '[data-js="events"]' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect()
          window['lazyload'] = new LazyLoad()
          changeSelect('#form-news-select')
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          overlayCalendar()
          calendar()
          tailSelect()
          window['lazyload'] = new LazyLoad()
          changeSelect('#form-events-select')
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'eventDetailView',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'projectsListView',
        beforeEnter() {
          tailSelect()
          window['lazyload'] = new LazyLoad()
          changeSelect('#form-projects-select')
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'projectDetailView',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
          new Accordions()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'packagesListView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'packageDetailView',
        beforeEnter() {
          new Accordions()
          dynamicPackage()
          swiperImages()
          overlayBuy()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          formContact()
          new Accordions()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-rubrics"]', scrollTo: '[data-js="rubrics"]' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'directoryView',
        beforeEnter() {
          tailSelect()
          new Accordions()
          overlayFilters()
          changeRubricTitle()
          changeSelect('#form-directory-select-sectors')
          changeSelect('#form-directory-select-order')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'sectionView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'memberView',
        beforeEnter() {
          dynamicPackage()
          swiperImages()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'publicPurchaseView',
        beforeEnter() {
          swiperNavigationHorizontal()
          formsPackage()
          masksPackage()
          formPublicPurchase('#form-public-purchase', '#form-public-purchase-submit')
          formPromoCode('#form-promo-code-desktop', '#form-promo-code-desktop-submit')
          formPromoCode('#form-promo-code-mobile', '#form-promo-code-mobile-submit')
          overlayRecapBlock()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'paymentPurchaseView',
        beforeEnter() {
          swiperNavigationHorizontal()
          formsPackage()
          masksPackage()
          formPaymentPurchase('#form-payment-purchase', '#form-payment-purchase-submit')
          formPromoCode('#form-promo-code-desktop', '#form-promo-code-desktop-submit')
          formPromoCode('#form-promo-code-mobile', '#form-promo-code-mobile-submit')
          overlayRecapBlock()
          overlayPurchaseConditions()
          acceptConditions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'purchaseConfirmationView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'membersOnlyView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'connexionView',
        beforeEnter() {
          formsPackage()
          formConnexion('#form-connexion', '#form-connexion-submit')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'reinitialisationView',
        beforeEnter() {
          formsPackage()
          formReinitialisation('#form-reinitialisation', '#form-reinitialisation-submit')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'membersPurchaseView',
        beforeEnter() {
          formPromoCode('#form-promo-code-desktop', '#form-promo-code-desktop-submit')
          formPromoCode('#form-promo-code-mobile', '#form-promo-code-mobile-submit')
          overlayRecapBlock()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'networkingView',
        beforeEnter() {
          overlayPopup()
          new Accordions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'myNetworksView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'networkingEditView',
        beforeEnter() {
          formsPackage()
          formNetworking('#form-networking', '#form-networking-submit')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'myAccountView',
        beforeEnter() {
          // overlayPopup()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'profileCompanyView',
        beforeEnter() {
          formsPackage()
          formProfileCompany('#form-profile-company', '#form-profile-company-submit')
          swiperNavigationHorizontal()
          window['lazyload'] = new LazyLoad()
        },
        afterEnter() {
          masksPackage()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'profilePersonnalView',
        beforeEnter() {
          formsPackage()
          formProfilePersonnal('#form-profile-personnal', '#form-profile-personnal-submit')
          swiperNavigationHorizontal()
          window['lazyload'] = new LazyLoad()
        },
        afterEnter() {
          masksPackage()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'profileSubscriptionsView',
        beforeEnter() {
          swiperNavigationHorizontal()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
    ]
  })
}
