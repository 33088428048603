import { OBSERVER } from '../main'
import Overlay from '../vendor/overlay.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'

// Fonction gérant l'overlay share
export function overlayShare() {

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    alert('L’adresse URL a bien été copiée dans votre presse-papier.')
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    event: 'click',
    target: '.js-share-copy',
    function: confirmClipboardCopy
  })
  OBSERVER.on('confirmClipboardCopy')

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    event: 'click',
    target: '.js-share',
    function: openShareOverlay
  })
  OBSERVER.on('openShareOverlay')

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'closeShareOverlay',
    event: 'click',
    target: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay
  })
  OBSERVER.on('closeShareOverlay')

}

// Fonction gérant l'overlay menu
export function overlayMenu() {
  new Overlay({
    name: 'menu',
    events: { close: true },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"]',
        switch: '[data-js="toggle-overlay-account"]'
      },
    },
    animations: {
      selector: '.overlay-menu__container',
      styles: [{
        property: 'height',
        value: '100%',
      }],
    },
    options: {
      speed: 900,
      goToSelector: 'html, body',
      closeOnResize: false,
    },
  }).init()
}

// Fonction gérant l'overlay popup
export function overlayPopup() {
  let popup = new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '[data-js="close-overlay-popup"]',
      }
    },
    timeout: {
      delay: 2000
    },
    options: {
      speed: 600,
      closeOnResize: false,
      disabledPageScroll: false,
    }
  })

  let overlayPopup = document.querySelector('.js-overlay-popup'),
    overlayPopupStickyTrack,
    maxWidthChangePositionSticky,
    mediaQuery,
    heightDocumentToDoChangePosition = 500,
    transitionDuration = 601,
    positionOverlayPopup = 'bottom'

  if (overlayPopup) {
    overlayPopupStickyTrack = document.querySelector('.js-overlay-popup-sticky-track')
    maxWidthChangePositionSticky = overlayPopup.dataset.overlayMaxWidthChangePositionSticky
    mediaQuery = window.matchMedia('(max-width: ' + maxWidthChangePositionSticky + 'px)')

    onScroll()
    popup.init()

    OBSERVER.add({ name: 'popup', event: 'scroll', function: onScroll })
    OBSERVER.add({ name: 'popup', event: 'resize', function: onResize })
    OBSERVER.add({ name: 'popup', event: 'click', target: '[data-js="close-overlay-popup"]', function: onClose })
    OBSERVER.on('popup')
  }

  function onScroll() {
    if(mediaQuery.matches) {
      if (document.documentElement.scrollTop >= heightDocumentToDoChangePosition) {
        changePositionOverlayPopup('top')
      }
      else {
        changePositionOverlayPopup('bottom')
      }
    }
  }

  function onResize() {
    if(mediaQuery.matches) {
      onScroll()
    }
    else {
      changePositionOverlayPopup('bottom')
    }
  }

  function changePositionOverlayPopup (position) {
    if (position === 'top' && positionOverlayPopup !== 'top') {
      popup.close()
      positionOverlayPopup = 'top'

      setTimeout(() => {
        overlayPopupStickyTrack.style.alignItems = 'flex-start'
        popup.open()
      }, transitionDuration)
    }
    else if (position === 'bottom' && positionOverlayPopup !== 'bottom') {
      popup.close()
      positionOverlayPopup = 'bottom'

      setTimeout(() => {
        overlayPopupStickyTrack.style.alignItems = 'flex-end'
        popup.open()
      }, transitionDuration)
    }
  }

  function onClose() {
    OBSERVER.off('popup')
    OBSERVER.remove('popup')
  }
}

// Fonction gérant l'overlay account
export function overlayAccount() {
  new Overlay({
    name: 'account',
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-account"]',
        switch: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-account"]',
      },
    },
  }).init()
}

// Fonction gérant l'overlay buy
export function overlayBuy() {
  let buy = new Overlay({
    name: 'buy',
    click: {
      buttons: {
        close: '[data-js="close-overlay-buy"]',
      },
    },
    timeout: {
      delay: 2000
    },
    options: {
      speed: 600,
      closeOnResize: false,
      disabledPageScroll: false,
    }
  })

  let overlayBuy = document.querySelector('.js-overlay-buy'),
    overlayBuyStickyTrack,
    maxWidthChangePositionSticky,
    mediaQuery,
    heightDocumentToDoChangePosition = 500,
    transitionDuration = 601,
    positionOverlayBuy = 'bottom'

  if (overlayBuy) {
    overlayBuyStickyTrack = document.querySelector('.js-overlay-buy-sticky-track')
    maxWidthChangePositionSticky = overlayBuy.dataset.overlayMaxWidthChangePositionSticky
    mediaQuery = window.matchMedia('(max-width: ' + maxWidthChangePositionSticky + 'px)')

    onScroll()
    buy.init()

    OBSERVER.add({ name: 'buy', event: 'scroll', function: onScroll })
    OBSERVER.add({ name: 'buy', event: 'resize', function: onResize })
    OBSERVER.on('buy')
  }

  function onScroll() {
    if(mediaQuery.matches) {
      if (document.documentElement.scrollTop >= heightDocumentToDoChangePosition) {
        changePositionOverlayBuy('top')
      }
      else {
        changePositionOverlayBuy('bottom')
      }
    }
  }

  function onResize() {
    if(mediaQuery.matches) {
      onScroll()
    }
    else {
      changePositionOverlayBuy('bottom')
    }
  }

  function changePositionOverlayBuy (position) {
    if (position === 'top' && positionOverlayBuy !== 'top') {
      buy.close()
      positionOverlayBuy = 'top'

      setTimeout(() => {
        overlayBuyStickyTrack.style.alignItems = 'flex-start'
        buy.open()
      }, transitionDuration)
    }
    else if (position === 'bottom' && positionOverlayBuy !== 'bottom') {
      buy.close()
      positionOverlayBuy = 'bottom'

      setTimeout(() => {
        overlayBuyStickyTrack.style.alignItems = 'flex-end'
        buy.open()
      }, transitionDuration)
    }
  }
}

// Fonction gérant l'overlay de calendrier
export const overlayCalendar = () => {
  let calendar = new Overlay({
    name: 'calendar',
    events: {
      close: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-calendar"]',
        close: '[data-js="close-calendar"]',
      }
    },
    options: {
      speed: 800,
      closeOnResize: false,
    }
  })
  
  calendar.init()
}

// Fonction gérant l'overlay page-popup
export function overlayPagePopup() {
  let pagePopup = new Overlay({
    name: 'page-popup',
    create: {
      background: false
    },
    timeout: {
      delay: 300,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-page-popup'
      }
    },
    options: {
      speed: 800,
    }
  })

  let divContainer = document.querySelector('.js-overlay-page-popup-container')
  let divContent = document.querySelector('.js-overlay-page-popup-padding')

  if(document.querySelector('[data-overlay="page-popup"]')) {
    onScroll()
    pagePopup.init()

    OBSERVER.add({ name: 'page-popup', event: 'resize', function: onResize })
    OBSERVER.add({ name: 'page-popup', event: 'scroll', function: onScroll })
    OBSERVER.on('page-popup')
  }

  // Set Height au container
  function onScroll() {
    let divContentHeight = divContent.clientHeight
    divContainer.style.height = divContentHeight + 'px'
  }

  // Set Height au container
  function onResize() {
    let divContentHeight = divContent.clientHeight
    divContainer.style.height = divContentHeight + 'px'
  }
}

// Fonction gérant l'overlay filters
export function overlayFilters() {
  let filters = new Overlay({
    name: 'filters',
    click: {
      buttons: {
        open: '[data-js="open-overlay-filters"]',
        close: '[data-js="close-overlay-filters"]',
      },
    },
    options: {
      speed: 600,
    },
  })

  filters.init()
}

// Fonction gérant l'overlay recap-block
export function overlayRecapBlock() {
  let recapBlock = new Overlay({
    name: 'recap-block',
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-recap-block"]',
        close: '[data-js="close-overlay-recap-block"]',
        switch: '[data-js="toggle-overlay-menu"]',
      },
    },
    animations: {
      addTransition: true,
      selector: '.overlay-recap-block__container',
      styles: [{
        property: 'height',
        value: 'tallestChild',
        easing: 'easeInOutQuart'
      }]
    },
    options: {
      speed: 800,
      closeOnResize: true
    }
  })

  let divScrolling = document.querySelector('.js-overlay-recap-block-scroll')
  let scrollTop = document.documentElement.scrollTop
  let pageTopHeight = document.querySelector('.js-page-top-purchase').clientHeight
  let buttonRecapBlockHeight = document.querySelector('.js-button-recap-block').clientHeight

  onScroll()
  recapBlock.init()

  // Set max-height du div scrolling
  function onResize() {
    pageTopHeight = document.querySelector('.js-page-top-purchase').clientHeight
    onScroll()
  }

  // Set max-height du div scrolling
  function onScroll() {
    let hundredVH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    let divScrollingMaxHeight = hundredVH - buttonRecapBlockHeight

    if(!document.querySelector('html').classList.contains('disabled-page-scroll')) {
      scrollTop = document.documentElement.scrollTop
    }

    if (scrollTop < pageTopHeight) {
      divScrollingMaxHeight = divScrollingMaxHeight - (pageTopHeight - scrollTop)
    }

    divScrolling.style.maxHeight = divScrollingMaxHeight + 'px'
  }

  OBSERVER.add({ name: 'recap-block', event: 'resize', function: onResize })
  OBSERVER.add({ name: 'recap-block', event: 'scroll', function: onScroll })
  OBSERVER.on('recap-block')
}

// Fonction gérant l'overlay purchase-conditions
export function overlayPurchaseConditions() {
  let purchaseConditions = new Overlay({
    name: 'purchase-conditions',
    click: {
      buttons: {
        open: '[data-js="open-overlay-purchase-conditions"]',
        close: '[data-js="close-overlay-purchase-conditions"]',
      }
    },
    options: {
      speed: 800,
    }
  })

  let divContainer = document.querySelector('.js-overlay-purchase-conditions-container')
  let divContent = document.querySelector('.js-overlay-purchase-conditions-padding')

  onScroll()
  purchaseConditions.init()

  // Set Height au container
  function onScroll() {
    let divContentHeight = divContent.clientHeight
    divContainer.style.height = divContentHeight + 'px'
  }

  // Set Height au container
  function onResize() {
    let divContentHeight = divContent.clientHeight
    divContainer.style.height = divContentHeight + 'px'
  }

  OBSERVER.add({ name: 'purchase-conditions', event: 'resize', function: onResize })
  OBSERVER.add({ name: 'purchase-conditions', event: 'scroll', function: onScroll })
  OBSERVER.on('purchase-conditions')
}