import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER } from '../main'
import { formatBytes, isMobile } from './helper'
import { infoBubble } from './functions'


// Le package à appliquer pour les formulaires
export function formsPackage() {
  inputsAndTextareaLabel()
  fileUpload()
  logoUpload()
  bannerUpload()
  multipleFilesUpload()
  tailSelect()
  textareaHeight()
  calendarInput()
  timepicker()
  passwordVisibility()
  infoBubble({ toggle: '.js-info-bubble-button', activeClass: 'show-info-bubble' })
}


// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel() {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], input[type="tel"], textarea',
    formElements = document.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = e => e.target.parentNode.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue
  })

  OBSERVER.on('inputsAndTextareaLabel')
}


// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight() {
  const formTextareas = document.querySelectorAll('textarea')

  const onLoad = () => {
    for(let i = 0; i < formTextareas.length; i++) {
      formTextareas[i].style.height = '5px'
      formTextareas[i].style.height = `${formTextareas[i].scrollHeight <= 43 ? 43 : formTextareas[i].scrollHeight}px`
    }
  }

  const onResize = () => onLoad()

  const onInput = e => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`
  }

  onLoad()
  OBSERVER.add({ name: 'textareaHeight', event: 'resize', function: onResize })
  OBSERVER.add({ name: 'textareaHeight', event: 'input', target: 'textarea', function: onInput })
  OBSERVER.on('textareaHeight')
}


// Permet de supprimer le contenu d'un input
export const clearInput = () => {
  const onClick = e => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    target: '.js-clear-input',
    function: onClick
  })

  OBSERVER.on('clearInput')
}


// Reset les dropzones du formulaire
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  let dropZoneList = $('.fileField', $(formObj))

  // reset des dropzones du form
  dropZoneList.each(function (index, element) {
    // pour chaque drop zone trouvé dans le form
    // on cherche son instance de Dropzone
    let dropZoneInstance = getDropzone(element.id)
    dropZoneInstance.removeAllFiles()
  })
}


// Retourne la dropZone en fonction de son nom
export function getDropzone(name) {
  let selectedDropzone = false
  Dropzone.instances.forEach(function (dropzone) {
    if (dropzone.element.id == name) selectedDropzone = dropzone
  })
  return selectedDropzone
}


// Permet de changer le label des input files
export function fileUpload() {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = e => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i = 0; i < fileLength; i++) {
          let file = e.currentTarget.files[i]
          newLabel += `${(i + 1)}. `

          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file].js-file-field-input',
    function: changeLabel
  })

  OBSERVER.on('fileUpload')
}


// Permet de changer l'image des input logo
export function logoUpload() {
  const targetContainer = '.js-logo-field'
  const targetButtonDelete = '.js-logo-field-preview-delete'
  const targetImage = '.js-logo-field-preview-image'

  const changeLogo = e => {
    const input = e.currentTarget
    const container = input.closest(targetContainer)
    const ButtonDelete = container.querySelector(targetButtonDelete)
    const image = container.querySelector(targetImage)

    if (input.files && input.files[0]) {
      $(ButtonDelete).show()
      image.src = URL.createObjectURL(input.files[0])
      image.onload = function() {
        URL.revokeObjectURL(image.src)
      }
    }
  }

  const deleteLogo = e => {
    const ButtonDelete = e.currentTarget
    const container = ButtonDelete.closest(targetContainer)
    const image = container.querySelector(targetImage)

    $(ButtonDelete).hide()
    image.src = image.getAttribute('data-default-src')
  }

  OBSERVER.add({ name: 'logoUpload',  event: 'click',  target: '.js-logo-field-preview-delete',  function: deleteLogo })
  OBSERVER.add({ name: 'logoUpload',  event: 'change',  target: 'input[type=file].js-logo-field-input',  function: changeLogo })
  OBSERVER.on('logoUpload')
}


// Permet de changer l'image des input banner
export function bannerUpload() {
  const targetContainer = '.js-banner-field-container'
  const targetPreview = '.js-banner-field-preview'
  const targetImage = '.js-banner-field-preview-image'

  const changeBanner = e => {
    const input = e.currentTarget
    const container = input.closest(targetContainer)
    const preview = container.querySelector(targetPreview)
    const image = container.querySelector(targetImage)

    if (input.files && input.files[0]) {
      image.src = URL.createObjectURL(input.files[0])
      image.onload = function() {
        URL.revokeObjectURL(image.src)
      }
      preview.classList.remove('hide')
    }
  }

  const deleteBanner = e => {
    const ButtonDelete = e.currentTarget
    const container = ButtonDelete.closest(targetContainer)
    const preview = container.querySelector(targetPreview)

    preview.classList.add('hide')
  }

  OBSERVER.add({ name: 'bannerUpload',  event: 'click',  target: '.js-banner-field-preview-delete',  function: deleteBanner })
  OBSERVER.add({ name: 'bannerUpload',  event: 'change',  target: 'input[type=file].js-banner-field-input',  function: changeBanner })
  OBSERVER.on('bannerUpload')
}


// Permet d'ajouter plusieurs fichiers (sans Renatio)
export function multipleFilesUpload() {
  const addItem = (e) => {
    const originalID = e.currentTarget.id
    const originalName = e.currentTarget.name
    const inputContainer = e.currentTarget.parentNode
    const containerList = e.currentTarget.parentNode.parentNode.parentNode.querySelector('.js-multiple-files-field-files-list')
    const template = e.currentTarget.parentNode.parentNode.querySelector('.js-multiple-files-field-file-block-template').content.cloneNode(true)

    if ($(e.currentTarget).get(0).files.length > 0) {
      const id = makeid()
      const file = $(e.currentTarget).get(0).files[$(e.currentTarget).get(0).files.length - 1]

      let previewSrc = '/themes/' + window.config.theme_path + '/assets/medias/images/default/multiple-files-field-preview-default.png'
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        previewSrc = URL.createObjectURL(file)
      }

      template.querySelector('.js-multiple-files-field-file-image').src = previewSrc
      template.querySelector('.js-multiple-files-field-file-title').textContent = `${file.name}`
      template.querySelector('.js-multiple-files-field-file-size').textContent = `${formatBytes(file.size)}`
      template.querySelector('.js-multiple-files-field-file-instance').setAttribute('id', `container-${id}`)
      containerList.appendChild(template)
      e.currentTarget.setAttribute('id', id)
      document.querySelector(`#container-${id}`).appendChild(e.currentTarget)
      let height = `${document.querySelector(`#${id}`).parentNode.querySelector('.js-multiple-files-field-file-height').clientHeight}px`

      anime.timeline({
        targets: document.querySelector(`#${id}`).parentNode,
        duration: 300,
        easing: 'easeOutCubic'
      }).add({
        height: height,
        opacity: 1,
      }).add({
        height: '100%'
      })
    }

    $('.js-multiple-files-field-file-delete').click(function (e) {
      anime.timeline({
        targets: e.currentTarget.parentNode.parentNode.parentNode,
        duration: 300,
        easing: 'easeOutCubic'
      }).add({
        height: '0px',
        opacity: 0,
      }).finished.then(() => {
        e.target.parentNode.parentNode.parentNode.parentNode.remove()
      })
    })

    const newInput = document.createElement('input')
    newInput.setAttribute('class', 'multiple-files-field__button-input || js-multiple-files-field-button-input')
    newInput.setAttribute('type', 'file')
    newInput.setAttribute('name', originalName)
    newInput.setAttribute('id', originalID)
    newInput.setAttribute('accept', '.pdf, .jpg, .png')

    inputContainer.appendChild(newInput)

    OBSERVER.off('multipleFilesUpload')
    OBSERVER.on('multipleFilesUpload')
  }

  const makeid = () => {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let charactersLength = characters.length
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  OBSERVER.add({ name: 'multipleFilesUpload', event: 'change', target: 'input[type=file].js-multiple-files-field-button-input', function: addItem })
  OBSERVER.on('multipleFilesUpload')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect() {
  let themePath = window.config.theme_path

  if (!isMobile())
    tail.select('select', { animate: false })

  // Ajouter les images dans les options du Tail Select
  let selectBoxes, selectBoxesLength, i, j
  selectBoxes = document.querySelectorAll('.tail-select')
  selectBoxesLength = selectBoxes.length

  for (i = 0; i < selectBoxesLength; i++) { //Looper dans chaques boites de sélections Tail Select
    let tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option')
    let nativeSelect = selectBoxes[i].previousElementSibling
    let nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)')

    // Ajouter l'icone en symbole
    let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/' + themePath + '/assets/medias/images/icons/symbols.svg#ico-pointer')
    svgElem.appendChild(useElem)
    //selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem)

    for (j = 0; j < nativeSelectItems.length; j++) { //Looper dans chaques item du Tail Select
      let imgPath = nativeSelectItems[j].dataset.image
      if ((typeof imgPath !== 'undefined') && imgPath != '') {
        let newImage = document.createElement('img')
        newImage.src = imgPath
        tailSelectItems[j].classList.add('has-image')
        tailSelectItems[j].appendChild(newImage)
      }
    }
  }

  // Gérer le changement de choix dans le select pour mettre l'image dans la boite du résultat sélectionné
  // Pas utilisé pour le moment
  /*
  OBSERVER.add({
    name: 'tailSelectChange',
    event: 'change',
    root: document,
    target: '.field--select select',
    function: tailSelectChange
  })
  OBSERVER.on('tailSelectChange')

  function tailSelectChange(e){
    var tailActive = e.target.parentElement.querySelector('.label-inner')

    if(tailActive){
      var selectedTailItem = e.target.parentElement.querySelectorAll('.tail-select .dropdown-option')[e.target.selectedIndex-1]
      if(selectedTailItem.classList.contains('has-image')){
        var newImage = document.createElement('img')
        newImage.src = selectedTailItem.querySelector('img').src
        tailActive.appendChild(newImage)
      }
    }

  }
  */
}


// Initialisation du champ de timepicker
export function timepicker() {
  if (!isMobile()) {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down'
      },
    })
  }
}


// Initialisation du champ de calendrier
export function calendarInput() {

  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {

    $.fn.datepicker.dates['fr'] = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: 'Aujourd\'hui',
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0
    }

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate')
    })

    $('.input-calendar input').on('focus', ({ currentTarget }) => {
      const element = $(currentTarget).parent().parent().find('.datepickerWrapper')
      element.addClass('show')
    })

    $('.datepickerWrapper').on('changeDate', ({ currentTarget }) => {
      $(currentTarget).prev().find('input').val($(this).datepicker('getFormattedDate'))
      $('.datepickerWrapper').removeClass('show')
    })

    const closeCalendar = () => {
      let i, x = document.querySelectorAll('.datepickerWrapper')

      for (i = 0; i < x.length; i++)
        x[i].classList.remove('show')
    }

    const preventClose = e => e.stopPropagation()

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: '.input-calendar',
      function: preventClose
    })

    OBSERVER.on('input-calendar')

    // Si on est en mobile, mettre utiliser les calendriers en HTML5
  } else {
    $('.input-calendar input').attr('type', 'date')
  }
}


// Permet de changer le titre de la rubrique selon le checkbox coché dans la rubrique (un fake select)
export function changeRubricTitle() {
  let radioButtonChecked
  let radioButtonText
  let rubricParent
  let rubricTitle

  function onButtonRadioChange(e) {
    radioButtonChecked = e.target
    radioButtonText = radioButtonChecked.nextElementSibling.textContent
    rubricParent = radioButtonChecked.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
    rubricTitle = rubricParent.querySelector('.rubric__title')
    rubricTitle.innerHTML = radioButtonText
  }

  OBSERVER.add({
    name: 'onButtonRadioChange',
    event: 'change',
    target: '[data-js="filters-radio-input"]',
    function: onButtonRadioChange
  })

  OBSERVER.on('onButtonRadioChange')
}


// Permet de changer la visibilité du mot de passe
export function passwordVisibility() {
  function togglePasswordVisibility(e) {
    let checkboxPasswordVisibility = e.currentTarget
    let containerFieldPassword = checkboxPasswordVisibility.closest('.js-input-field')
    let fieldPassword = containerFieldPassword.querySelector('.js-input-field-input')

    if (fieldPassword.type === 'password') {
      fieldPassword.type = 'text'
    } else {
      fieldPassword.type = 'password'
    }
  }

  OBSERVER.add({ name: 'passwordVisibility',  event: 'click',  target: '.js-password-visibility-input',  function: togglePasswordVisibility })
  OBSERVER.on('passwordVisibility')
}

export function acceptConditions() {
  const falseCheckbox = document.querySelector('[data-js="conditions-checkbox"]');
  const checkbox = document.querySelector('#form-payment-purchase-accept-conditions');
  const button = document.querySelector('#form-payment-purchase-submit');

  function onClick() {
    checkbox.click()
  }

  OBSERVER.add({ name: 'checkboxChecked',  event: 'click',  target: falseCheckbox,  function: onClick })
  OBSERVER.on('checkboxChecked')
}
