import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, isMobile } from './helper.js'
import { Accordions } from './../classes/accordions.js'

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })

  return animation.finished
}


// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}


// Fonction permettant de show/hide une bulle d'informations
export function infoBubble (options) {
  const toggle = document.querySelectorAll(options.toggle)

  if(!isMobile()) {
    const hoverIn = e => {
      e.currentTarget.nextElementSibling.classList.add(options.activeClass)
    }
    const hoverOut = e => {
      e.currentTarget.nextElementSibling.classList.remove(options.activeClass)
    }

    OBSERVER.add({ name: 'hover',  event: 'mouseenter',  target: toggle,  function: hoverIn })
    OBSERVER.add({ name: 'hover',  event: 'mouseleave',  target: toggle,  function: hoverOut })
    OBSERVER.on('hover')
  }
  else if(isMobile()) {
    OBSERVER.add({ name: 'touch',  event: 'click',  target: toggle,  function: touch })
    OBSERVER.on('touch')
  }

  function touch(e) {
    const isInfoBubbleVisible = e.currentTarget.nextElementSibling.classList.contains(options.activeClass)

    if(isInfoBubbleVisible) {
      e.currentTarget.nextElementSibling.classList.remove(options.activeClass)
    } else {
      e.currentTarget.nextElementSibling.classList.add(options.activeClass)
    }
  }
}


// Permet de faire apparaitre un bouton back to top, ainsi que des liens de partages
export const backToTopAndLinks = (options = {}) => {

  // Déclaration des propriétés par défaut
  options.container         === undefined ? options.container         = '[data-js="back-to-top-and-links"]' : options.container
  options.backToTopSelector === undefined ? options.backToTopSelector = '[data-js="back-to-top"]'           : options.backToTopSelector
  options.distance          === undefined ? options.distance          = 500                                 : options.distance
  options.showClass         === undefined ? options.showClass         = 'displayed'                         : options.showClass

  // Déclarations des variables
  const container = document.querySelector(options.container)
  const shareAccordion = new Accordions({
    scrollToAccordion: false,
    containerClass: 'js-accordions-share',
    accordionClass: 'js-accordion-share',
    toggleButtonClass: 'js-accordion-share-toggle',
    contentClass: 'js-accordion-share-content',
    openedClass: 'js-accordion-share-opened',
  })

  // Déclarations des fonctions
  const manageBackToTop = () => {
    if(document.documentElement.scrollTop >= options.distance) {
      container.classList.add(options.showClass)
    } else {
      container.classList.remove(options.showClass)
      shareAccordion.closeAll()
    }
  }

  // Si le container existe
  if (typeof(container) != 'undefined' && container != null) {

    // Permet de retourner en haut de la page
    clickToScrollToBlock({ selector: options.backToTopSelector })

    // Appel de la fonction au moins une fois
    manageBackToTop()

    // Ajout de l événement pour masquer ou afficher le contenu
    OBSERVER.add({ name: 'manageBackToTop', event: 'scroll', function: manageBackToTop })
    OBSERVER.on('manageBackToTop')
  }
}

export function listLoaded(data) {
  if (!data['hasMorePages'])
    $('#listMoreBtn').hide()
  else {
    $('#listMoreBtn').show()
  }
}

export function changeSelect(select) {
  let $parent = $(select).parent()
  $('.dropdown-option',$parent).on('click', function() {
    $(select).trigger('change')
  })
}
